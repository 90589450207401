import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { OrderForListPage } from '@obeta/models/lib/schema-models/order-list'
import styles from './OrderInfo.module.scss'
import { TextBubble } from '../text-bubble/TextBubble'
import { dateFormatter } from '@obeta/utils/lib/dateFormatter'
import { getTranslationString } from '@obeta/utils/lib/translation-helpers'
import { ORDER_TYPE_TRANSLATIONS } from '@obeta/models/lib/models/Orders/OrderListViewModels'
import { OrderType } from '@obeta/schema'

type Props = {
  order: OrderForListPage
  isMinimisedStartPageVersion?: boolean
}

export const OrderInfo: FC<Props> = (props) => {
  const { order, isMinimisedStartPageVersion = false } = props

  const { t } = useTranslation()

  // Often-case the customer reference is simply a (character-limited) copy, derived from the commissionText. If so, we
  // will hide it.
  const isCustomerReferenceDerivedFromCommissionText =
    order.customerReference !== '' && order.commissionText.indexOf(order.customerReference) === 0

  // Note: If dealing with an online order we will hide the clerk
  // Note: Clerk is not part of SearchOrdersStartPageQuery and thus may NOT be set
  const isOnlineOrder = order.clerk && order.clerk.startsWith('SHOPUSER')

  // Note: If dealing with an interface aka EDI order, we will overwrite the purchaser value (and hide the clerk)
  const isInterfaceOrder = order.type === 'Interface'
  let purchaserValue
  if (isInterfaceOrder) {
    purchaserValue = t('ORDERS.INFO.INTERFACE')
  } else {
    purchaserValue = order.purchaser
  }

  return (
    <div className={styles.info}>
      <TextBubble
        chromaticIgnore
        title={t('ORDERS.INFO.DATE')}
        value={dateFormatter(order.createdAt)}
      />
      {!isMinimisedStartPageVersion && (
        <TextBubble
          title={t('ORDERS.INFO.TYPE')}
          value={t(getTranslationString<OrderType>(ORDER_TYPE_TRANSLATIONS, order.type))}
        />
      )}
      {order.collector && order.collector !== '' && !isMinimisedStartPageVersion && (
        <TextBubble title={t('ORDERS.INFO.COLLECTOR')} value={order.collector} />
      )}
      {!isMinimisedStartPageVersion && (
        <TextBubble title={t('ORDERS.INFO.PURCHASER')} value={purchaserValue} />
      )}
      {/* Hide clerk for interface or online orders, on start page or if it equals the purchaser value */}
      {order.clerk &&
        order.clerk !== '' &&
        !isInterfaceOrder &&
        !isOnlineOrder &&
        !isMinimisedStartPageVersion &&
        order.clerk !== order.purchaser && (
          <TextBubble title={t('ORDERS.INFO.CLERK')} value={order.clerk} />
        )}
      {order.projectName !== '' && !isMinimisedStartPageVersion && (
        <TextBubble title={t('ORDERS.INFO.PROJECT_NAME')} value={order.projectName} />
      )}
      {order.customerReference &&
        !isCustomerReferenceDerivedFromCommissionText &&
        !isMinimisedStartPageVersion && (
          <TextBubble title={t('ORDERS.INFO.CUSTOMER_REFERENCE')} value={order.customerReference} />
        )}
      {order.commissionText && (
        <TextBubble title={t('ORDERS.INFO.COMMISSION')} value={order.commissionText} />
      )}
      {order.offerId && !isMinimisedStartPageVersion && (
        <TextBubble title={t('ORDERS.INFO.OFFER_NUMBER')} value={order.offerId} />
      )}
    </div>
  )
}
