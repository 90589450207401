import dayjs from 'dayjs'

// Models
import {
  OrderItemStateType,
  OrderOrderBy,
  OrderOrderDir,
  OrderV2,
  OrdersFacetV2,
  OrdersInput,
} from '@obeta/schema'
import { ConsolidatedHistory } from '@obeta/models/lib/models/History'
import {
  DEFAULT_ORDER_LIST_FILTERS,
  DEFAULT_ORDER_LIST_ITEM_LIMIT,
  DEFAULT_ORDER_LIST_QUERY_PARAMS,
  OrderListURLSearchParams,
} from '@obeta/models/lib/models/Orders'
import { OrderForListPage } from '@obeta/models/lib/schema-models/order-list'
import { QueryParams } from '@obeta/models/lib/models/VirtualizedList'
import { getURLSearchParamsByLocationSearch, updateURLSearchParams } from './virtualized-list'

/**
 * Create OrdersInput by query params and url search params for GraphQL.
 * @param queryParams Query params
 * @param urlSearchParams Url search params
 * @returns OrdersInput
 */
export const createOrdersInputByQueryParamsAndURLSearchParams = (
  queryParams: QueryParams,
  urlSearchParams: OrderListURLSearchParams
): OrdersInput => {
  return {
    // deprecated?
    filter: [],
    // deprecated
    filterV2: {
      dateFrom: urlSearchParams.dateFrom ?? dayjs().subtract(6, 'month').format('YYYY-MM-DD'),
      dateTo: urlSearchParams.dateTo ?? dayjs().format('YYYY-MM-DD'),
      itemStates:
        (urlSearchParams.itemStates?.split(',') as OrderItemStateType[]) ??
        DEFAULT_ORDER_LIST_FILTERS.itemStates,
      orderType: urlSearchParams.orderType?.split(',') ?? DEFAULT_ORDER_LIST_FILTERS.orderType,
      projectName:
        urlSearchParams.projectName?.split(',') ?? DEFAULT_ORDER_LIST_FILTERS.projectName,
      purchaser: urlSearchParams.purchaser?.split(',') ?? DEFAULT_ORDER_LIST_FILTERS.purchaser,
      shippingType:
        urlSearchParams.shippingType?.split(',') ?? DEFAULT_ORDER_LIST_FILTERS.shippingType,
      supplierIds:
        urlSearchParams.supplierIds?.split(',') ?? DEFAULT_ORDER_LIST_FILTERS.supplierIds,
    },
    includeArchived: true,
    limit: queryParams.limit ?? DEFAULT_ORDER_LIST_QUERY_PARAMS.limit,
    offset: queryParams.offset ?? DEFAULT_ORDER_LIST_QUERY_PARAMS.offset,
    orderBy: (urlSearchParams.orderBy as OrderOrderBy) ?? 'orderId',
    orderDir: (urlSearchParams.orderDir as OrderOrderDir) ?? 'DESC',
    orderItemLimit: DEFAULT_ORDER_LIST_ITEM_LIMIT,
    searchTerm: urlSearchParams.searchTerm ?? DEFAULT_ORDER_LIST_FILTERS.searchTerm,
  }
}

/**
 * Filter Facet by search string.
 * @param facet OrdersFacet
 * @param search Search string
 * @returns Filtered Facet
 */
export const filterBySearch = (facet: OrdersFacetV2[], search: string): OrdersFacetV2[] => {
  return facet.filter((facetItem) => facetItem.name.toLowerCase().includes(search.toLowerCase()))
}

/**
 * Get date strings for url query params. Only set date strings if not default.
 * @param dateFrom Date from
 * @param dateTo Date to
 * @returns Date strings
 */
export const getOrderListDateStrings = (
  dateFrom: string | undefined,
  dateTo: string | undefined
): { dateFrom: string | undefined; dateTo: string | undefined } => {
  return {
    dateFrom: dateFrom !== DEFAULT_ORDER_LIST_FILTERS.dateFrom ? dateFrom : undefined,
    dateTo: dateTo !== DEFAULT_ORDER_LIST_FILTERS.dateTo ? dateTo : undefined,
  }
}

/**
 * Get order list filter toggle state by location search, key and un/checked value.
 * @param search Location search
 * @param key Key
 * @param value Un/checked value
 * @returns Order list filter toggle state
 */
export const getOrderListFilterToggleState = (
  search: string,
  key: string,
  value: string
): boolean => {
  const params = getURLSearchParamsByLocationSearch<OrderListURLSearchParams>(search)
  const values = params[key]?.split(',') ?? []
  return values.includes(value)
}

/**
 * Get order list filter value by location search and key.
 * @param search Location search
 * @param key Key
 * @returns Order list filter value (default: initial value)
 */
export const getOrderListFilterValue = (search: string, key: string) => {
  const params = getURLSearchParamsByLocationSearch<OrderListURLSearchParams>(search)
  return params[key] ?? DEFAULT_ORDER_LIST_FILTERS[key]
}

/**
 * Get order list search term value by search params
 * @param search Location search
 * @returns Search term value
 */
export const getOrderListSearchTermValue = (search: string): string => {
  const params = getURLSearchParamsByLocationSearch<OrderListURLSearchParams>(search)
  return params.searchTerm ?? ''
}

/**
 * Check if any order list filter value in url search params is set.
 * @param search Location search
 * @returns Boolean
 */
export const isOrderListDefaultFilterValues = (search: string): boolean => {
  if (
    getOrderListFilterValue(search, 'itemStates').length > 0 ||
    getOrderListFilterValue(search, 'orderType').length > 0 ||
    getOrderListFilterValue(search, 'projectName').length > 0 ||
    getOrderListFilterValue(search, 'purchaser').length > 0 ||
    getOrderListFilterValue(search, 'searchTerm').length > 0 ||
    getOrderListFilterValue(search, 'shippingType').length > 0
  ) {
    return false
  }

  return true
}

export const isPrintAvailable = (order: OrderV2): boolean => {
  // The service to retrieve order confirmations via SAP has only been available from 2024-05-24
  const dateOrderCreated = dayjs(order.createdAt, 'YYYY-MM-DD')
  const datePrintActivated = dayjs('2024-05-24', 'YYYY-MM-DD')

  if (dateOrderCreated.isBefore(datePrintActivated, 'day') || order.type === 'Retoure') {
    return false
  }

  return true
}

/**
 * Sort OrdersFacet by custom order.
 * @param facet OrdersFacet
 * @param customOrder Custom order
 * @returns Sorted OrdersFacet
 */
export const sortFacetByCustomOrder = (
  facet: OrdersFacetV2[],
  customOrder: string[]
): OrdersFacetV2[] => {
  return facet?.sort((a, b) => customOrder.indexOf(a.name) - customOrder.indexOf(b.name))
}

/**
 * Sort OrdersFacet by name.
 * @param facet OrdersFacet
 * @returns Sorted OrdersFacet array
 */
export const sortFacetByName = (facet: OrdersFacetV2[]) => {
  return facet?.sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1))
}

/**
 * Toggle order list filter by url history, key and value.
 * @param history OrderListURLSearchParams
 * @param key key
 * @param value Un/checked value
 */
export const toggleOrderListFilter = (
  history: ConsolidatedHistory<unknown>,
  key: string,
  value: string
) => {
  // Extract url search params
  let params = getURLSearchParamsByLocationSearch<OrderListURLSearchParams>(history.location.search)
  const values = params[key]?.split(',') ?? []
  if (values.includes(value)) {
    // Remove checked value from filter list
    params = {
      ...params,
      [key]: values.filter((item) => item !== value).join(),
    }
  } else {
    // Add new checked value to filter list
    params = {
      ...params,
      [key]: [...values, value].join(),
    }
  }
  updateURLSearchParams(params, history, '/orders-list')
}

/**
 * Updates the displayed order name in list page.
 * @param id Order id
 * @param name Order name
 * @param orders OrderForListPage array
 * @returns Updated OrderForListPage array
 */
export const updateNameInListPage = (
  id: string,
  name: string,
  orders: OrderForListPage[]
): OrderForListPage[] => {
  const updatedOrder = orders.find((order) => order.id === id)
  if (updatedOrder) {
    updatedOrder.name = name
  }

  return [...orders]
}

/**
 * Modifies an orderId that may contain an additional postfix to ensure it isunique.
 * Whilst this is crucial for communicating with the other endpoints we don't want to expose this postfix to the users
 */
export const modifyOrderIdForDisplay = (orderId: string): string => {
  const dashIndex = orderId.indexOf('-')

  if (dashIndex !== -1) {
    return orderId.substring(0, dashIndex)
  }

  return orderId
}
