import { FC } from 'react'
import { Typography } from '@mui/material'
import styles from './TextBubble.module.scss'
import clsx from 'clsx'

type Props = {
  backgroundColor?: 'gray' | 'white'
  chromaticIgnore?: boolean
  size?: 'small' | 'large'
  title: string
  value: string
}

export const TextBubble: FC<Props> = (props) => {
  const { backgroundColor = 'gray', chromaticIgnore, size = 'small', title, value } = props

  return (
    <div className={clsx(styles.textBubble, styles[backgroundColor], styles[size])}>
      <Typography variant="smallText">{title}</Typography>
      <Typography data-chromatic={chromaticIgnore && 'ignore'} variant="smallTextBold">
        {value}
      </Typography>
    </div>
  )
}
