import { getLocale } from './getLocale'

/**
 * Format date.
 * @param date Date
 * @returns Formatted date
 */
export const dateFormatter = (date: Date | string | number) => {
  // todo: Replace hard coded locale with getLocale() once i18n is in usage
  return getDateString(date, 'de-DE')
}

/**
 * Format date by current locale.
 * @param date Date
 * @returns Formatted date with time
 */
export const dateTimeFormatter = (date: Date | string | number) => {
  const d = new Date(date)
  const locale = getLocale()
  const dateStr = getDateString(d, locale)
  const timeStr = d.toLocaleTimeString().slice(0, 5)

  return `${dateStr} ${timeStr}`
}

/**
 * Get date string by date and locale
 * @param date Date
 * @param locale Locale
 * @returns Formatted date
 */
const getDateString = (date: Date | string | number, locale: string): string => {
  return `${new Date(date).toLocaleDateString(locale, {
    day: '2-digit',
    year: 'numeric',
    month: '2-digit',
  })}`
}

/**
 * Calculate the day difference from 2 dates.
 * @param date1 Date
 * @param date2 Date
 * @returns Day difference
 */
export const getDayDifference = (date1: Date, date2: Date): number => {
  // Calculate the time difference in milliseconds
  const timeDifference = date2.getTime() - date1.getTime()

  // Convert milliseconds to days
  return Math.floor(timeDifference / (1000 * 60 * 60 * 24))
}
