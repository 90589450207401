import { useContext } from 'react'
import { VirtualizedListAnimationContext } from './virtualized-list-animation-context'
import { virtualizedListAnimationOffsetTopVar } from './virtualized-list-animation-vars'

export interface UseVirtualizedListAnimationRowReturn {
  getStyle(style: React.CSSProperties, index: number): React.CSSProperties
}

export function useVirtualizedListAnimationRowStyle(): UseVirtualizedListAnimationRowReturn {
  const listAnimationContext = useContext(VirtualizedListAnimationContext)

  function getStyle(style: React.CSSProperties, index: number): React.CSSProperties {
    if (
      !listAnimationContext ||
      !listAnimationContext.isAnimating ||
      index <= listAnimationContext.rowIndex
    ) {
      return style
    }

    const top = typeof style.top === 'number' ? `${style.top}px` : style.top

    return {
      ...style,
      top: top ? `calc(${top} + var(${virtualizedListAnimationOffsetTopVar}, 0px))` : undefined,
    }
  }

  return { getStyle }
}
