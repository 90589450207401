import { FC } from 'react'
import { Typography } from '@mui/material'
import clsx from 'clsx'

// Styles
import styles from './OrderShippingItem.module.scss'

type Props = {
  detailView?: boolean
  justifyContent?: 'center' | 'flex-end'
  title: string
  value: string
}

export const OrderShippingItem: FC<Props> = (props) => {
  const { detailView, justifyContent, title, value } = props

  return (
    <div
      className={clsx(styles.shipping, detailView && styles.shippingDetail)}
      style={{
        justifyContent: justifyContent ?? 'flex-start',
      }}
    >
      <Typography
        className={clsx(styles.shippingTitle, detailView && styles.shippingTitleDetail)}
        variant="smallText"
      >
        {title}
      </Typography>
      <Typography
        className={clsx(styles.shippingValue, detailView && styles.shippingValueDetail)}
        variant="smallTextBold"
      >
        {value}
      </Typography>
    </div>
  )
}
