import { createContext } from 'react'

export interface VirtualizedListAnimationContextValue {
  isAnimating: boolean
  rowIndex: number
  startAnimating: (rowIndex: number) => void
  onHeightChange: (height: number) => void
  stopAnimating: () => void
}

export const VirtualizedListAnimationContext =
  createContext<VirtualizedListAnimationContextValue | null>(null)
