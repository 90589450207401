/**
 * Extract query params as object from url string.
 * @param search History location search
 * @returns URL search params object
 */
export const getURLSearchParamsByLocationSearch = <T>(search: string): T => {
  const urlSearchParams = new URLSearchParams(search)
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return Object.fromEntries(urlSearchParams.entries())
}
