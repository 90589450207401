import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Typography } from '@mui/material'
import clsx from 'clsx'
import { OrderShippingItem } from './OrderShippingItem'
import { OrderShippingType } from '@obeta/schema'
import styles from './OrderShipping.module.scss'
import { dateFormatter } from '@obeta/utils/lib/dateFormatter'
import { getTranslationString } from '@obeta/utils/lib/translation-helpers'
import { ORDER_SHIPPING_TYPE_TRANSLATIONS } from '@obeta/models/lib/models/Orders'

type Props = {
  address: string
  date: string
  detailView?: boolean
  gap?: boolean
  type: OrderShippingType
}

export const OrderShipping: FC<Props> = (props) => {
  const { address, date, detailView, gap, type } = props

  const { t } = useTranslation()

  return (
    <div className={styles.shipping}>
      {detailView && <Typography variant="bodyBold">{t('ORDERS.SHIPPING.TITLE')}</Typography>}
      <div className={clsx(styles.shippingItems, gap && styles.gap)}>
        <OrderShippingItem
          detailView={detailView}
          title={t('ORDERS.SHIPPING.TYPE.TITLE')}
          value={t(getTranslationString<OrderShippingType>(ORDER_SHIPPING_TYPE_TRANSLATIONS, type))}
        />
        <OrderShippingItem
          detailView={detailView}
          justifyContent="center"
          title={t('ORDERS.SHIPPING.DESIRED_DATE')}
          value={dateFormatter(date)}
        />
        <OrderShippingItem
          detailView={detailView}
          justifyContent="flex-end"
          title={t('ORDERS.SHIPPING.ADDRESS')}
          value={address}
        />
      </div>
    </div>
  )
}
