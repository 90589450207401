import { FormHelperText as MuiFormHelperText, SvgIcon, Typography, useTheme } from '@mui/material'
import { FC, memo } from 'react'
import clsx from 'clsx'

// Assets
import { ReactComponent as WarningIcon } from 'assets/icon/designsystem/warning.svg'

// Styles
import styles from './FormHelperText.module.scss'

type FormHelperTextProps = {
  className?: string
  color?: string
  errorText: string
  icon?: FC
}

/**
 * Component to display a helper text at form fields.
 */
export const FormHelperText: FC<FormHelperTextProps> = memo((props) => {
  const theme = useTheme()

  const { className, color, errorText, icon } = props

  return (
    <MuiFormHelperText className={clsx(styles.helperText, className)}>
      <SvgIcon
        className={styles.helperTextIcon}
        component={icon ?? WarningIcon}
        fontSize="small"
        htmlColor={color ?? theme.palette.primary.main}
      />
      <Typography variant="smallText" color={theme.palette.primary.main}>
        {errorText}
      </Typography>
    </MuiFormHelperText>
  )
})
