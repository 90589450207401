import { gql } from '@apollo/client'

export const HAS_ORDERS_QUERY = gql`
  query hasOrders {
    hasOrders {
      success
      errorMessage
      errorCode
      totalOrderCount
    }
  }
`
