import { ConsolidatedHistory } from '@obeta/models/lib/models/History'

/**
 * Update URLSearchParams by filtered values.
 * @param urlSearchParams URLSearchParams
 * @param history History
 * @param pathname Pathname
 * @param removeKey Key to remove from URLSearchParams
 */
export const updateURLSearchParams = (
  /* eslint-disable @typescript-eslint/no-explicit-any */
  urlSearchParams: { [key: string]: any },
  history: ConsolidatedHistory<unknown>,
  pathname: string,
  removeKey?: string
) => {
  // Filter out empty values
  let filteredQueryParams = Object.fromEntries(
    Object.entries(urlSearchParams).filter(([key, value]) => {
      return value !== null && value !== undefined && value !== '' && value.length !== 0
    })
  )

  // Filter out specific key
  if (removeKey) {
    filteredQueryParams = Object.fromEntries(
      Object.entries(filteredQueryParams).filter(([key]) => {
        return key !== removeKey
      })
    )
  }

  // Replace url search params with current values
  history.replace({
    pathname,
    search: new URLSearchParams(
      filteredQueryParams as
        | string
        | string[][]
        | Record<string, string>
        | URLSearchParams
        | undefined
    ).toString(),
  })
}
