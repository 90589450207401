import { useEventCallback } from '@mui/material/utils'
import { useCallback, useMemo, useState } from 'react'
import { VirtualizedListAnimationContextValue } from './virtualized-list-animation-context'

export interface UseVirtualizedListAnimationOptions {
  onHeightChange: (heightOffset: number | null) => void
}

export interface UseVirtualizedListAnimationReturn {
  context: VirtualizedListAnimationContextValue
}

type VirtualizedListAnimationState = Pick<
  VirtualizedListAnimationContextValue,
  'isAnimating' | 'rowIndex'
>

export function useVirtualizedListAnimationState(
  options: UseVirtualizedListAnimationOptions
): UseVirtualizedListAnimationReturn {
  const handleHeightChange = useEventCallback(options.onHeightChange)

  const [state, setState] = useState<VirtualizedListAnimationState>({
    isAnimating: false,
    rowIndex: 0,
  })

  const startAnimating = useCallback(
    (rowIndex: number) => {
      handleHeightChange(0)
      setState({ isAnimating: true, rowIndex })
    },
    [handleHeightChange]
  )

  const stopAnimating = useCallback(() => {
    handleHeightChange(null)
    setState({ isAnimating: false, rowIndex: 0 })
  }, [handleHeightChange])

  const context = useMemo<VirtualizedListAnimationContextValue>(() => {
    return {
      isAnimating: state.isAnimating,
      rowIndex: state.rowIndex,
      startAnimating: startAnimating,
      onHeightChange: handleHeightChange,
      stopAnimating: stopAnimating,
    }
  }, [state.isAnimating, state.rowIndex, startAnimating, handleHeightChange, stopAnimating])

  return {
    context,
  }
}
